.sign_h2 {
  font: normal normal medium 30px/46px Poppins;
  letter-spacing: 0px;
  color: #0f0c1a;
  text-transform: uppercase;
}

.sign_in_all {
  background: linear-gradient(45deg, #343a40 0 50%, #0c1117 50% 100%)
}

.sign_main {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.sign_in_card {
  width: 600px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
}

.form2 {
  margin-top: 30px;
}

.form2>input {
  height: 60px;
  background: #f5f7fb 0% 0% no-repeat padding-box;
  border-radius: 5px;
}

.form2_btn {
  background: #c4bfdf 0% 0% no-repeat padding-box;
  border-radius: 5px;
  margin-top: 30px;
  font-weight: 600;
  width: 100%;
  color: #0f0c1a;
  height: 62px;
}

@media only screen and (max-width:600px) {
  .sign_in_card {
    width: 100%;
  }
}